import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';
import { Hero, Ram, SectionCard } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allServices__landing_pages {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
          }
        }
      }
      areas1: allService_areas {
        edges {
          node {
            _meta {
              id
              uid
            }
            name
            thumbnail
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        totalCount
      }
      areas2: allService_areas(after: "YXJyYXljb25uZWN0aW9uOjE5", first: 90) {
        edges {
          node {
            _meta {
              id
              uid
            }
            name
            thumbnail
          }
          cursor
        }
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`;

const SectionCardList = styled(Box)`
  &:after {
    content: '';
    flex: 1 1;
  }
`;

export default function({ data }) {
  // Set content
  const content = data.prismic.content.edges[0].node;
  const areas1 = data.prismic.areas1.edges
  .map((edge) => {
    return {
      id: edge.node._meta.id,
      name: edge.node.name[0].text,
      href: `/services/${edge.node._meta.uid}`,
      image: edge.node.thumbnail,
    };
  })
  const areas2 = data.prismic.areas2.edges
    .map((edge) => {
      return {
        id: edge.node._meta.id,
        name: edge.node.name[0].text,
        href: `/services/${edge.node._meta.uid}`,
        image: edge.node.thumbnail,
      };
    })

  const areas = areas1.concat(areas2).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  function mainPadding(size) {
    switch (size) {
      case 'small':
        return { horizontal: 'medium' };
      case 'medium':
        return 'large';
      default:
        return 'xlarge';
    }
  }

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.hero_image && content.hero_image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Hero
              imageMobile={content.hero_image.mobile.url}
              imageDesk={content.hero_image.url}
              heroHeader={content.hero_header}
              heroCopy={content.hero_copy}
              htmlSerializer={htmlSerializer}
            />
            <Box pad={mainPadding(size)} align="center">
              <SectionCardList
                direction="row-responsive"
                justify="between"
                width="1440px"
                margin={{ horizontal: 'auto' }}
                wrap
              >
                {areas.map((area) => {
                  return (
                    <SectionCard
                      Link={Link}
                      key={area.id}
                      label={area.name}
                      serviceImage={area.image}
                      href={area.href}
                    />
                  );
                })}
              </SectionCardList>
            </Box>
            <Ram color="E63D2F" />
          </>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
